html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2b4257;
}

h1 {font-size: 2rem}
h2 {font-size: 1.8rem}
h3 {font-size: 1.6rem}
h4 {font-size: 1.4rem}
h5 {font-size: 1.2rem}
h6 {font-size: 1.0rem}

/* Required for responsive tables */
.react-bootstrap-table table {
  table-layout: auto !important;
}


/* Default button theming for PWT */
.pwt-button {
  background-color: #21436D !important;
  border-color: #21436D !important;
  color: #ffffff !important;
}


.pwt-button:active, 
.pwt-button:focus,
.pwt-button:target, 
.pwt-button:active:focus {
  background-color: #1A3557 !important;
  border-color: #1A3557 !important;
  color: #ffffff !important;
}

.pwt-button-secondary {
  background-color:#868e96 !important;
  border-color: #868e96 !important;
  color: #ffffff !important;
}

.pwt-button-secondary:active, 
.pwt-button-secondary:focus, 
.pwt-button-secondary:target, 
.pwt-button-secondaryactive:focus {
  background-color: #6B7178 !important;
  border-color: #6B7178 !important;
  color: #ffffff !important;
}

/* Navigation Bar theming */
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:target,
.dropdown-item:active:focus {
  background-color: #21436D !important;
  color: white !important;
}

.navbar-nav .nav-link, .dropdown-item {
  color: #21436D !important;
}

.nav-link.disabled {
  color: #868e96 !important;
}

.dropdown-item.active {
  color: #787F87 !important;
  background-color: whitesmoke !important;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav {
  color: #868e96 !important;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: #868e96 !important;
  background-color: whitesmoke;
}

.dropdown-menu, .bg-light {
  background-color: whitesmoke !important;
}

.navbar-nav.nav-tabs {
  background-color: whitesmoke !important;
}



@media (hover: hover) and (pointer: fine) {
  .pwt-button:hover {
    background-color: #1D3C62 !important;
    border-color: #1D3C62 !important;
    color: #ffffff !important;
  }
  .pwt-button-secondary:hover {
    background-color: #787F87 !important;
    border-color: #787F87 !important;
    color: #ffffff !important;
  }
  
  .nav-link:hover {
    color: #787F87 !important;
  }
  .dropdown-item:hover {
    color: #787F87 !important;
    background-color: whitesmoke !important;
  }

}


